<template>
  <div>
    <div class="my-investments-holder" v-if="smartlockData.length > 0">
      <div class="d-flex justify-content-between align-content-center">
        <h5 class="my-investment-text">My Investments</h5>
        <router-link to="investment-transactions">
          <h5 class="see-more">See More <i class="fa fa-chevron-right"></i></h5>
        </router-link>
      </div>
      <div
        class="investments-wrap"
        @click="smartLockDetailsCheck(smart)"
        v-for="(smart, index) in smartlockData"
        :key="index"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="investment-left">
              <img
                src="../../assets/images/invest_card_icon.svg"
                alt="invest"
              />
              <div>
                <h6>Smart lock</h6>
                <h5>{{ smart.startdate | moment2 }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="investment-right">
              <h6>₦{{ Number(smart.amountpersave).toLocaleString() }}</h6>
              <h5>Earned: ₦{{ Number(smart.interestsum).toLocaleString() }}</h5>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
import $ from "jquery";
import PageLoader from "../PageLoader.vue";
import Pagination from "../Pagination.vue";
import moment from "moment";
export default {
  name: "SmartLock",
  components: {
    Pagination,
    PageLoader
  },
  // props: ["cooporativeData"],
  data() {
    return {
      loading: false,
      page: 1,
      limit: 10,
      hideDiv: false,
      smartlockData: []
    };
  },
  watch: {},
  // computed: {
  //   welcome() {
  //     return `${this.cooporativeData}`;
  //   }
  // },
  created() {
    this.$root.$refs.fwi = this;
  },
  mounted() {
    this.getSmartLocks();
  },
  methods: {
    async getSmartLocks() {
      try {
        await Api.getRequest("fetchusersmartlock")
          .then(res => {
            this.smartlockData = res.data.smart_lock;
          })
          .catch(err => {
            console.log("err", err);
            // this.fundLoaderBlue = false;
          });
      } finally {
        // console.log('closed');
      }
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    moment3: function(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$gray: #d7d7d7;
$yellow: #ffb100;
$shelta-black: #2b3352;
$shelta-grey: #555c74;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

// My Investments starts
.my-investments-holder {
  padding: 20px 14px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: $secondary;
  border-radius: 4px;
}
.investments-wrap {
  cursor: pointer;
}
.my-investment-text {
  color: $shelta-grey;
  text-align: left;
  font-family: Lato;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
}
.investment-left {
  display: flex;
  gap: 10px;
  div {
    h6 {
      text-align: left;
      color: $shelta-black;
      font-family: Lato;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4px;
      text-transform: capitalize;
    }
    h5 {
      text-align: left;
      color: $shelta-grey;
      font-family: Lato;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 0px;
    }
  }
}
.investment-right {
  h6 {
    text-align: right;
    color: $shelta-black;
    font-family: Lato;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.5px;
  }
  h5 {
    text-align: right;
    color: $shelta-grey;
    font-family: Lato;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 599px) {
  .investment-right {
    padding-left: 40px;
    margin-top: 10px;
    h6,
    h5 {
      text-align: left;
    }
  }
}
// My Investments ends
</style>
